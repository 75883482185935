<template>
    <v-content>
        <div v-if="! moduleReport">
            <v-breadcrumbs :items="items" large></v-breadcrumbs>
            <v-row>
              
                <v-col
                        cols="2"
                        v-for="title in titles"
                        v-bind:key="title.link"
                        class="viewCard"
                >
                    <v-tooltip right>
                        <template v-slot:activator="{ on }">
                            <router-link :to="title.link">
                              
                                <HoverCard :cardData="title"></HoverCard>

                            </router-link>
                        </template>
                    </v-tooltip>
                </v-col>
            </v-row>
        </div>
    </v-content>
</template>

<script>
    import HoverCard from '@/components/Basic/HoverCard'
    export default {
        name: "Dashboard",
        watch: {},
        components: {
            HoverCard
        },
        computed: {
            titles() {
                return [
                    {
                        name: this.$t('immigration_form'),
                        link: "/Tourist-ArrivalCustomer",
                        icon: "mdi-earth-arrow-right"
                    },
                    {
                        name: this.$t('immigration_api'),
                        link: "/immigrationApiCheck",
                        icon: "mdi-api"
                    },
                    {
                        name: this.$t('import_immigration_raw_data'),
                        link: "/ImmigrationRawDataImport",
                        icon: "mdi-file-import"
                    },
                    {
                        name: this.$t('modules_name.tourist_arrival'),
                        link: "/reportModules/reportTourist-Arrival",
                        icon: "mdi-account-cowboy-hat-outline"
                    },
                    {
                        name: this.$t('modules_name.tourist_police'),
                        link: "/reportModules/reportTouristPolice",
                        icon: "mdi-account-arrow-left-outline"

                    },
                    {
                        name: this.$t('modules_name.tourist_vehicle'),
                        link: "/reportModules/reportTouristVehicle",
                        icon: "mdi-car-convertible"
                    },
                    {
                        name: this.$t('dot'),
                        link: "/reportModules/reportDot",
                        icon: "mdi-store"
                    },
                    {
                        name: this.$t('modules_name.mountaineering'),
                        link: "/reportModules/reportMountaineering",
                        icon: "mdi-image-filter-hdr"
                    },
                    {
                        name: this.$t('modules_name.hotel'),
                        link: "/reportModules/reportHotel",
                        icon: "mdi-bed-outline"
                    },
                    {
                        name: this.$t('modules_name.home_stay'),
                        link: "/reportModules/reportDashboardHomeStay",
                        icon: "mdi-home-silo"

                    },
                    {
                        name: this.$t('modules_name.income_generation'),
                        link: "/reportModules/reportIncomeGenerationDashboard",
                        icon: "mdi-cash-multiple"
                    },
                    
                    {
                        name: this.$t('modules_name.central_bureau'),
                        link: "/reportModules/reportCentralBureau",
                        icon: "mdi-account-group"
                    },
                    {
                        name: this.$t('modules_name.national_park'),
                        link: "/reportModules/reportNationalPark",
                        icon: "mdi-car-brake-parking"
                    },
                    {
                        name: this.$t('modules_name.pilgrimage'),
                        link: "/reportModules/reportPilgrimage",
                        icon: "mdi-temple-buddhist-outline"
                    },
                    {
                        name: this.$t('modules_name.trekking'),
                        link: "/reportModules/reportTrekking",
                        icon: "mdi-google-street-view"
                    },
                    
                    {
                        name: this.$t('modules_name.aviation'),
                        link: "/reportModules/reportAviation",
                        icon: "mdi-airplane"
                    },
                    {
                        name: this.$t('modules_name.academic'),
                        link: "/reportModules/reportAcademic",
                        icon: "mdi-town-hall"
                    },
                    
                    {
                        name: this.$t('modules_name.airports'),
                        link: "/reportModules/reportAirport",
                        icon: "mdi-airport"
                    },
                    {
                        name: this.$t('nma'),
                        link: "/reportModules/reportNMA",
                        icon: "mdi-image-filter-hdr"
                    },
                    
                    
                    
                ]
            }
        },
        data() {
            return {

                /*    modules: [
                        {
                            name: "Trekking",
                        }
                    ],*/
                items: [
                    {
                        text: "",
                        disabled: true,
                        to: "/dashboard",
                        exact: true
                    }],
                moduleReport: false,
                moduleName: '',
                componentName: ''
            }
        },
        mounted() {
            this.formData()
        },
        methods: {
            formData() {

            },

            goBackToReportSelection() {
                this.componentName = 'selectReportTypeComponent'
            },

            goBackToReportDashboard() {
                this.moduleReport = false
            },

            goToReport(type) {
                this.componentName = type
                console.log('this,moduleName: ', this.moduleName)
            },

            goToModuleReport(moduleName) {
                this.moduleName = moduleName
                this.componentName = 'selectReportTypeComponent'
                this.moduleReport = true
            }
        }
    };
</script>

<style lang="scss" scoped>
    a {
        text-decoration: none;
    }
</style>